import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Layout from '../components/Layout';
import { Link as RouterLink } from 'gatsby';
import SEO from '../components/SEO';



const backgroundImage = 'https://media.graphcms.com/resize=w:1080,fit:crop/y6V3VVGSRkq0jH7NJd9N';
// const backgroundImage = 'https://media.graphcms.com/resize=w:1080,fit:crop/sepia=tone:80/torn_edges=spread:[1,10]/vyl1yGJUSgiYbarZHqJd';
// const backgroundImage = 'https://source.unsplash.com/tzQkuviIuHU';
//const backgroundImage = 'https://source.unsplash.com/NMk1Vggt2hg';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

export default function () {
  const classes = useStyles();

  return (
    <Layout>

      <SEO 
        title="Page Not Found"
        description="This is spooky! That page must be a ghost."
      />

      <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${backgroundImage})` }}>
        {/* Increase the priority of the hero background image */}
        {<img style={{ display: 'none' }} src={backgroundImage} alt={'Spooky Cabin In The Woods'} />}
        <div className={classes.overlay} />
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography variant="h4" color="inherit" paragraph>
                Page Not Found
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                This is spooky! That page must be a ghost.
              </Typography>
              <Button
                component={RouterLink} 
                to='/'
                  variant="outlined"
                  color="primary"
                >
                  Back to home
                  </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
};